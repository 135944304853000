import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';

import Box from '@freska-fi/ui-box';
import Typography from '@freska-fi/ui-typography';
import ThemeProvider from '@freska-fi/ui-theme';

import { getActiveReferralCoupon } from '../../store/active-referral-coupon/selectors';
import { fetchRatingBooking } from '../../store/booking/actions';
import { getRating, getAverageRating } from '../../store/rating/selectors';
import { getFetchRatingBookingStatus } from '../../store/status/selectors';
import { Dispatch } from '../../store/types';

import AppCard from '../AppCard/AppCard';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Layout from '../Layout/Layout';
import MainCard from '../MainCard/MainCard';
import PageLoader from '../PageLoader/PageLoader';
import CustomerReviewGoogleCard from '../CustomerReview/CustomerReviewGoogleCard';

const REFERRAL_PROMO_RATING_THRESHOLD = 4;

export interface RateBookingConfirmationParams {
  id: string;
}

export interface RateBookingConfirmationProps extends RouteComponentProps<RateBookingConfirmationParams> {}

export default function RateBookingConfirmation({ id, navigate }: RateBookingConfirmationProps) {
  const dispatch = useDispatch<Dispatch>();
  const status = useSelector(getFetchRatingBookingStatus);
  const referralCoupon = useSelector(getActiveReferralCoupon);
  const average = useSelector(getAverageRating);
  const rating = useSelector(getRating);

  useEffect(() => {
    if (id) {
      dispatch(fetchRatingBooking(id));
    }
  }, [dispatch, id]);

  // Navigate to feedback form if not yet rated
  useEffect(() => {
    if (status === 'SUCCESS' && !rating) {
      navigate?.('..');
    }
  }, [status, rating, navigate]);

  if (status === 'NOT_ASKED' || status === 'LOADING' || !rating) {
    return <PageLoader />;
  }

  return (
    <Layout>
      <MainCard>
        {status === 'FAILURE' ? (
          <ErrorMessage id="Something went wrong!" />
        ) : (
          <Box textAlign="center">
            <Typography component="h1" variant="h1" mb={3}>
              <FormattedMessage id="Thank you!" />
            </Typography>

            <Typography variant="body1" mb={5}>
              <FormattedMessage id="You help us improve. Looking forward to the next time!" />
            </Typography>

            {referralCoupon && average && average >= REFERRAL_PROMO_RATING_THRESHOLD ? (
              <ThemeProvider>
                <CustomerReviewGoogleCard />
              </ThemeProvider>
            ) : (
              <AppCard />
            )}
          </Box>
        )}
      </MainCard>
    </Layout>
  );
}
