import React, { useEffect } from 'react';

import { customerReviewGoogleCardSeen } from '../../utilities/tracking';

import InnerCard from '../InnerCard/InnerCard';
import config from '../../config';
import { FormattedMessage } from 'react-intl';
import Box from '@freska-fi/ui-box';
import Button from '@freska-fi/ui-button';
import Typography from '@freska-fi/ui-typography';
import Link from '@freska-fi/ui-link';

export default function CustomerReviewGoogleCard() {
  useEffect(() => customerReviewGoogleCardSeen(), []);

  return (
    <InnerCard fullWidth>
      <Box mb={4}>
        <Box textAlign="center">
          <Box mb={2}>
            <Typography component="h2" variant="h2">
              <FormattedMessage id="We'd love if you left us a rating on Google!" />
            </Typography>
          </Box>
          <Typography variant="body1">
            <FormattedMessage id="Could you take a moment to leave us an honest rating on Google?" />{' '}
            <FormattedMessage id="It would mean a lot to us, helping spread happiness to more customers and creating more jobs for our cleaners." />
          </Typography>
        </Box>
      </Box>

      <Box>
        <Link href={`${config.googleReviewUrl}`} target="_blank">
          <Button sx={theme => ({ px: theme.spacing(3), py: theme.spacing(2) })} size="large" color="primary">
            <Box display="flex" alignItems="center">
              <Box ml={1}>
                <FormattedMessage id="Give us a rating on Google" />
              </Box>
            </Box>
          </Button>
        </Link>
      </Box>
    </InnerCard>
  );
}
