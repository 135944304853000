import { Locale } from '../store/locale/types';

import fi from './fi';
import no from './no';
import se from './se';

export interface Config {
  countryCode: string;
  defaultLocale: Locale;
  currencySign: string;
  checkoutUrl: string;
  siteUrl: string;
  appStoreUrl: string;
  googlePlayUrl: string;
  googleReviewUrl: string;
  termsAndConditionsUrl: Record<string, string>;
  privacyPolicyUrl: Record<string, string>;
  freskaAppUrl: Record<string, string>;
}

function resolveSiteConfig(): Config {
  switch (process.env.REACT_APP_COUNTRY) {
    case 'no':
      return no;
    case 'se':
      return se;
    case 'fi':
    default:
      return fi;
  }
}

export default resolveSiteConfig();
