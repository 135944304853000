export function track(event: string) {
  window.analytics && window.analytics.track(event);
}

export function trackPage() {
  window.analytics && window.analytics.page();
}

export function trackRatingStarted() {
  track('Rating Started');
}

export function trackRatingSubmitted() {
  track('Rating Submitted');
}

export function trackReferralShareCardSeen() {
  track('Referral Share Card Seen');
}

export function trackReferralShareButtonClicked(type: string) {
  track(`${type} Referral Share Button Clicked`);
}

export function customerReviewGoogleCardSeen() {
  track('Customer Review Google Card Seen');
}
